import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import type { DatePickerProps, RadioChangeEvent } from "antd";
import { DatePicker, message, Radio, Space } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import {
  ButtonGroup,
  Snackbar,
  SnackbarCloseReason,
  Typography as Typo,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { IoIosArrowForward, IoIosSearch } from "react-icons/io";
import { FaBoxOpen, FaLocationCrosshairs } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { Container, Offcanvas } from "react-bootstrap";
import { IoCartSharp } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { Button, Modal } from "@mui/material";
import "./Header.css";
import { TbTruckDelivery } from "react-icons/tb";
import { IoBagOutline } from "react-icons/io5";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Checkbox, Dropdown, Flex, Input, Typography } from "antd";
import type { GetProps, MenuProps } from "antd";
import { Button as ButtonAnt } from "antd";
import { FaShoppingCart } from "react-icons/fa";
import { MdEdit, MdMyLocation } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { ApiEndPoints } from "../Providers/Api_endpoints";
import {
  amountTransfer,
  forgetpassUser,
  getAllorders,
  getMe,
  loginUser,
  postOrder,
  signupUser,
  updateuser,
} from "../Providers/Api_Calls";
import axios from "axios";
import Cookies from "js-cookie";
import BulkOrders from "./BulkOrders";
import Francies from "./Francies";
import { Moment } from "moment";
import moment from "moment";
import GoogleLogin from "react-google-login";

type OTPProps = GetProps<typeof Input.OTP>;

interface Location {
  latitude: number | null;
  longitude: number | null;
}

const { Title } = Typography;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: 4,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "45ch",
    },
  },
}));

interface LineItem {
  product: string;
  quantity: number;
  productPrice: number;
  discountInPercent: number;
  discountedPrice: number;
}

interface OrderData {
  orderId: number;
  lineItems: LineItem[];
  type: string;
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  paymentStatus: string;
  status: string;
  deliveryInstructions: string;
  isCancelled: boolean;
  cancellationReason: string | null;
  refundStatus: string;
  originalPrice: string | null;
}

interface CheckoutData {
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  paymentStatus: string;
  status: string;
  paymentAmount: number | null; // This should be number | null
  isMMpoints: boolean;
}

interface CheckoutDataH {
  lineItems: {
    product: string;
    quantity: number;
  }[];
  type: string;
  deliverySlot: string;
  address: string;
  paymentMethod: string;
  paymentStatus: string;
  status: string;
  deliveryInstructions: string;
  isCancelled: boolean;
  cancellationReason: string | null;
  refundStatus: string;
  paymentAmount: number | null; // Ensure paymentAmount is included
  totalAmount: number | null;
  originalPrice: number | null;
  isMMpoints: boolean;
}

// Declare the Razorpay type on the window object
declare global {
  interface Window {
    Razorpay: any;
  }
}

export default function Header(value: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [show, setShow] = React.useState(false);
  const [pincodeerror, setpincodeerror] = useState("");


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error"></Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <FaBoxOpen />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        ></IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const onChange: OTPProps["onChange"] = (text) => {
    console.log("onChange:", text);
  };

  const sharedProps: OTPProps = {
    onChange,
  };

  const [isSign, setIsSign] = useState({
    signup: false,
    forgetpass: false,
    otp: false,
    login: false,
    address: false,
    resetpassword: false,
  });

  const [isCart, setIsCart] = useState({
    cart: false,
    summary: false,
    newAddress: false,
    payment: false,
  });

  type Signupdata = {
    name: string;
    email: string;
    password: string;
    role: string;
    phone: number | null;
    gender: string;
  };

  const [isLocation, setIsLocation] = useState(false);
  const [isSignupData, setisSignupData] = useState<Signupdata>({
    name: "",
    email: "",
    password: "",
    role: "user",
    phone: null,
    gender: "",
  });
  const [isloginData, setIsloginData] = useState({
    email: "",
    password: "",
  });
  const [isForgetpass, setIsForgetpass] = useState("");
  const [location, setLocation] = useState<Location>({
    latitude: null,
    longitude: null,
  });
  const [address, setAddress] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [radiovalue, setRadiovalue] = useState("0");
  const [slotset, setSlotset] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [isConform, setIsConform] = useState(false);

  const onRadioChange = (e: any) => {
    const selectedIndex = e.target.value;
    setRadiovalue(selectedIndex);
    const newDate = moment()
      .add(parseInt(selectedIndex, 10), "days")
      .format("YYYY-MM-DD");
    setSelectedDate(newDate);
    setSlotset("");
  };

  const handleSlot = (slot: string) => {
    setSlotset(slot);
  };

  const isSlotDisabled = (slotTime: string) => {
    const currentDate = moment();
    const slotDate = moment().add(parseInt(radiovalue), "days");

    const slotMoment = moment(
      `${slotDate.format("YYYY-MM-DD")} ${slotTime}`,
      "YYYY-MM-DD hh:mm a"
    );

    return currentDate.add(1, "hours").isAfter(slotMoment);
  };

  const renderDayOptions = () => {
    return Array.from({ length: 6 }, (_, i) => {
      const dayLabel = moment().add(i, "days").format("MMM D");

      return (
        <Radio key={i} value={i.toString()}>
          {dayLabel}
        </Radio>
      );
    });
  };

  type UserData = {
    cart: any[];
    email: string;
    name: string;
    orders: any[];
    role: string;
    phone: number | null;
    address: {
      addressType: string;
      city: string;
      street: string;
      state: string;
      pincode: number | null;
    };
    _id: string;
    mmPoints: number;
  };

  const [userData, setUserData] = useState<UserData>({
    cart: [],
    email: "",
    name: "",
    orders: [],
    role: "",
    phone: null,
    address: {
      addressType: "",
      city: "",
      street: "",
      state: "",
      pincode: null,
    },
    _id: "",
    mmPoints: 0,
  });

  const [isTypebtn, setIstypeBtn] = useState({
    home: true,
    work: false,
    other: false,
  });

  const handleAddressTypeChange = (type: any) => {
    if (type === "Home") {
      setIstypeBtn({
        home: true,
        work: false,
        other: false,
      });
    } else if (type === "Work") {
      setIstypeBtn({
        home: false,
        work: true,
        other: false,
      });
    } else {
      setIstypeBtn({
        home: false,
        work: false,
        other: true,
      });
    }

    setUserData((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        addressType: type,
      },
    }));
  };

  const [total, setTotal] = useState<number>(0);
  const [checkout, setCheckout] = useState<CheckoutData>({
    deliverySlot: "",
    address: "",
    paymentMethod: "",
    paymentStatus: "pending",
    status: "",
    paymentAmount: null,
    isMMpoints: false,
  });

  const navigate = useNavigate();
  const handleOrders = () => {
    if (isUser) {
      navigate("/my-order");
    } else {
      errormessage("Please login !");
    }

    handleClose();
  };

  const handleLogin = (val: any) => {
    setIsCart({
      cart: false,
      summary: false,
      newAddress: false,
      payment: false,
    });
    switch (val) {
      case "signUp": {
        setIsSign({
          signup: true,
          forgetpass: false,
          otp: false,
          login: false,
          address: false,
          resetpassword: false,
        });
        break;
      }
      case "forgetPass": {
        setIsSign({
          signup: false,
          forgetpass: true,
          otp: false,
          login: false,
          address: false,
          resetpassword: false,
        });
        break;
      }
      case "login": {
        setIsSign({
          signup: false,
          forgetpass: false,
          otp: false,
          login: true,
          address: false,
          resetpassword: false,
        });
        break;
      }
      case "otp": {
        setIsSign({
          signup: false,
          forgetpass: false,
          otp: true,
          login: false,
          address: false,
          resetpassword: false,
        });
        break;
      }
      case "address": {
        setIsSign({
          signup: false,
          forgetpass: false,
          otp: false,
          login: false,
          address: true,
          resetpassword: false,
        });
        break;
      }
    }
  };

  const [isCartitem, setisCartitem] = useState(false);
  const handleNavCart = () => {
    setIsSign({
      signup: false,
      forgetpass: false,
      otp: false,
      login: false,
      address: false,
      resetpassword: false,
    });
    let data = localStorage.getItem("cart");
    if (data) {
      setIsCart({ ...isCart, cart: true });
    } else {
      // alert("No items in cart !...")
      errormessage("No items in cart !...");
      return;
    }
  };

  const handleNavProfile = () => {
    setIsCart({
      cart: false,
      summary: false,
      newAddress: false,
      payment: false,
    });
    setIsSign({ ...isSign, login: true });
  };

  const handleNaUserdetail = () => {
    setIsCart({
      cart: false,
      summary: false,
      newAddress: false,
      payment: false,
    });
    setIsSign({ ...isSign, address: true });
  };
  const pincode=["600007","600008","600020","600029","600040","600102","600023","600090","600108","600018","600005","600031","600002","600112","600094","600106","600003","600018","600025","600084","600009","600014","600006","600032","600015","600020","600104","600083","600020","600038","600036","600014","600082"]

  const handleCartNav = (val: any) => {

    setIsSign({
      signup: false,
      forgetpass: false,
      otp: false,
      login: false,
      address: false,
      resetpassword: false,
    });

  

    switch (val) {
      case "summary": {
        sessionStorage.setItem("openCart", "false");

        setIsCart({
          cart: false,
          summary: true,
          newAddress: false,
          payment: false,
        });

        let token = localStorage.getItem("authtoken");
        if (!token) {
          handleClicksnack();
          setIsCart({
            cart: false,
            summary: false,
            newAddress: false,
            payment: false,
          });
          setIsSign({
            signup: true,
            forgetpass: false,
            otp: false,
            login: false,
            address: false,
            resetpassword: false,
          });
          return;
        }

        const cartData = JSON.parse(localStorage.getItem("cart") || "[]");
        let totalPrice = 0;
        cartData.forEach((item: any) => {
          const discountedPrice = item.paymentAmount;
          totalPrice += parseFloat(discountedPrice);
        });

        // Adjust total price for delivery charges if applicable
        const calculatedTotal = totalPrice > 100 ? totalPrice : totalPrice + 25;
        const ddamount = checkout.isMMpoints
          ? calculatedTotal - userData.mmPoints
          : calculatedTotal;

        setTotal(ddamount);

        setCheckout((prev) => ({
          ...prev,
          paymentAmount: ddamount,
        }));

        break;
      }
      case "newaddress": {
     
        if (!slotset) {
          errormessage("Select slot");
          return;
        }
        setIsCart({
          cart: false,
          summary: false,
          newAddress: true,
          payment: false,
        });
        break;
      }
      case "payment": {
        if (pincode.some((pin) => userData.address.pincode?.toString().trim() === pin)) {
          setpincodeerror(""); // Clear the error if the pincode is valid
        } else {
          setpincodeerror("Unable to deliver this pincode!");
          return;
        }
        if (
          !userData.address.city ||
          !userData.address.pincode ||
          !userData.address.state ||
          !userData.address.street
        ) {
          errormessage("Address required");
          return;
        }
        handleupdateUser();

        // Handle MM points logic
        const mmPoints = userData.mmPoints || 0; // Get MM points from user data
        const paymentAmount = checkout.paymentAmount;

        // setCheckout((prev) => ({
        //   ...prev,
        //   isMMpoints: false, // Default value; updated when checkbox changes
        // }));

        setIsCart({
          cart: false,
          summary: false,
          newAddress: false,
          payment: true,
        });

        break;
      }
    }
  };

  const handleupdateUser = async () => {
    try {
      const updatedData = await updateuser(
        ApiEndPoints("updateuser"),
        userData._id,
        userData
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseIcon = () => {
    sessionStorage.setItem("cartopen", "false");
    setIsSign({
      signup: false,
      forgetpass: false,
      otp: false,
      login: false,
      address: false,
      resetpassword: false,
    });
    setresetMessage("");
    setIssignupError("");
    setIsloginError("");
  };

  const handlecartClose = () => {
    sessionStorage.setItem("openCart", "false");
    setIsCart({
      cart: false,
      summary: false,
      newAddress: false,
      payment: false,
    });
    setCheckout({ ...checkout, isMMpoints: false });
  };

  const [issnackbar, setissnackbar] = useState(false);
  const [snackbarCon, setsnackbarCon] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px", // Set the font size
        fontWeight: "bold", // Set the font weight
      },
    });
  };

  //Signup Function
  // const handleSignUP = async (e: any) => {
  //   e.preventDefault();

  //   if (
  //     (isSignupData.email === "" ||
  //       isSignupData.password==="" ||
  //       isSignupData.name === "")
  //   ) {
  //     errormessage()
  //     setsnackbarCon("All fields required !");

  //   } else if (isSignupData.password.length < 6) {
  //     setsnackbarCon("Password must be greater than 6letters");
  //     errormessage()
  //   } else {
  //     try {
  //       const result = await signupUser(ApiEndPoints("signUp"), isSignupData);
  //       setsnackbarCon("Signup success");
  //       setIsSign({
  //         signup: false,
  //         forgetpass: false,
  //         otp: false,
  //         login: true,
  //         address: false,
  //         resetpassword: false,
  //       });

  //       await successmessage()
  //     } catch (error: any) {
  //       console.log(error);
  //       if (
  //         error.response &&
  //         error.response.data &&
  //         error.response.data.message
  //       ) {
  //         if (error.response.data.message === "User already exists") {
  //           setsnackbarCon("User already exists. Please try logging in.");
  //           errormessage()
  //         } else {
  //           setsnackbarCon(error.response.data.message);
  //           errormessage()
  //         }
  //       } else {
  //         setsnackbarCon("An unexpected error occurred. Please try again.");
  //         errormessage()
  //       }
  //     }
  //   }
  // };
  const [errorAlert, setErrorAlert] = useState("");
  const [issignupError, setIssignupError] = useState("");
  const [isLogmail, setsLogmail] = useState("");
  // Signup Function
  const handleSignUP = async (e: any) => {
    e.preventDefault();
    setErrorAlert("");
    setIssignupError("");
    if (
      isSignupData.email === "" ||
      isSignupData.password === "" ||
      isSignupData.name === ""
    ) {
      setsnackbarCon("All fields required!");
      // errormessage("All fields required!");
      setIssignupError("All fields required!");
    } else if (isSignupData.password.length < 6) {
      setsnackbarCon("Password must be greater than 6 letters");
      // errormessage("Password must be greater than 6 letters");
      setIssignupError("Password must be greater than 6 letters");
    } else {
      try {
        const result = await signupUser(ApiEndPoints("signUp"), isSignupData);
        setsLogmail(isSignupData.email);
        setsnackbarCon("Signup success");
        successmessage("Signup success");
        setIssignupError("Signup success");
        setIsSign({
          signup: false,
          forgetpass: false,
          otp: false,
          login: true,
          address: false,
          resetpassword: false,
        });
      } catch (error: any) {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errormessage(error.response.data.message);
          setErrorAlert(error.response.data.message);
          setIssignupError(error.response.data.message);
        } else {
          setsnackbarCon("An unexpected error occurred. Please try again.");
          errormessage("An unexpected error occurred. Please try again.");
          setIssignupError("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  const [isloginError, setIsloginError] = useState("");

  //Login Function
  const handleLoginuser = async (e: any) => {
    e.preventDefault();

    if (isloginData.email === "" || isloginData.password === "") {
      // errormessage("Need email and password");
      setIsloginError("Need email and password");
    } else if (isloginData.password.length < 6) {
      // errormessage("Check password !");
      setIsloginError("Check password !");
    } else {
      setsnackbarCon("");
      setIsloginError("");
      try {
        const result = await loginUser(ApiEndPoints("login"), isloginData);
        if (result.token !== null && result.role === "user") {
          localStorage.setItem("authtoken", result.token);
          window.dispatchEvent(new Event("storage"));
          setIsSign({
            signup: false,
            forgetpass: false,
            otp: false,
            login: false,
            address: false,
            resetpassword: false,
          });
          successmessage("Login success");
          getUser();
          window.location.reload();
        } else {
          // errormessage("Please login as user...");
          setIsloginError("Please login as user...");
        }

        // getallOrders()
      } catch (error: any) {
        console.log(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.message === "User not found") {
            setsnackbarCon("User not found !");
          } else {
            setsnackbarCon(error.response.data.message);
          }
          errormessage(error.response.data.message);
          setIsloginError(error.response.data.message);
        } else {
          errormessage("An unexpected error occurred. Please try again.");
          setIsloginError("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  useEffect(() => {
    if (isLogmail) {
      setIsloginData((prevData) => ({
        ...prevData,
        email: isLogmail,
      }));
    }
  }, [isLogmail]);
  
  useEffect(() => {

  setpincodeerror("");

  }, []);

  //Logout
  const handlelogOut = () => {
    localStorage.clear();
    setIsSign({
      signup: false,
      forgetpass: false,
      otp: false,
      login: true,
      address: false,
      resetpassword: false,
    });
    Cookies.remove("token");
    window.location.reload();
  };

  //Forget Password
  const [resetMessage, setresetMessage] = useState("");
  const handleForgetPassword = async (e: any) => {
    e.preventDefault();

    if (isForgetpass === "") {
      setresetMessage("Fill email!");
    } else {
      setresetMessage("");
      try {
        const forpassResp = await forgetpassUser(
          ApiEndPoints("forgetPassword"),
          { email: isForgetpass }
        );
        setresetMessage(forpassResp.message);
        // handleLogin("otp");
      } catch (error) {
        console.log(error);
      }
    }
  };

  //Get location
  const handleLocatiocDetect = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          reverseGeocode(latitude, longitude);
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const reverseGeocode = async (latitude: number, longitude: number) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse`,
        {
          params: {
            lat: latitude,
            lon: longitude,
            format: "json",
          },
        }
      );
      const address = response.data.display_name;
      setAddress(address);
    } catch (error) {
      setError("Failed to fetch the address");
    }
  };

  //GetMe
  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));
      setUserData(getMeuser.user);
    } catch (error) {
      console.log(error);
    }
  };

  //Get all orders
  // const getallOrders = async () => {
  //   try {
  //     const getOrders = await getAllorders(ApiEndPoints("getallOrders"));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const [isUser, setisUser] = useState(false);
  React.useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("authtoken");
      setisUser(!!token);
      getUser();
    };

    checkToken();

    const handleStorageChange = () => {
      checkToken();
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  //Search
  const [isSearch, setisSearch] = useState("");

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setisSearch(value);
    navigate(`/search?query=${value}`);
  };

  //Mobilecart
  const handleMobileCart = () => {
    handleClose();
    setIsCart({ ...isCart, cart: true });
  };

  //MobileProfile
  const handlemobileProfile = () => {
    handleClose();
    if (isUser) {
      setIsSign({ ...isSign, address: true });
    } else {
      setIsSign({ ...isSign, login: true });
    }
  };

  const [cartItems, setCartItems] = useState<any[]>([]);

  // useEffect(() => {
  //   // Retrieve cart items from localStorage when component mounts
  //   const cartItemsStr = localStorage.getItem("cart");
  //   if (cartItemsStr) {
  //     try {
  //       const storedItems = JSON.parse(cartItemsStr);
  //       setCartItems(storedItems);
  //     } catch (error) {
  //       console.error("Failed to parse cart items from localStorage", error);
  //       setCartItems([]);
  //     }
  //   } else {
  //     setCartItems([]);
  //   }
  // }, []);

  useEffect(() => {
    // Function to fetch cart items from localStorage and update state
    const fetchCartItems = () => {
      const cartItemsStr = localStorage.getItem("cart");
      if (cartItemsStr) {
        try {
          const storedItems = JSON.parse(cartItemsStr);
          setCartItems(storedItems);
        } catch (error) {
          console.error("Failed to parse cart items from localStorage", error);
          setCartItems([]);
        }
      } else {
        setCartItems([]);
      }
    };
    setError("");
    // Initial fetch
    fetchCartItems();

    // Set up polling interval to check for updates in localStorage
    const intervalId = setInterval(fetchCartItems, 1000); // Polling interval in milliseconds

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const updateCartItem = (index: number, newQuantity: number) => {
    const updatedItems = [...cartItems];
    const item = updatedItems[index];

    // Calculate unit price
    const unitPrice = item.paymentAmount / item.lineItems[0].quantity;

    // Update the quantity
    item.lineItems[0].quantity = newQuantity;

    // Update the price
    item.paymentAmount = unitPrice * newQuantity;

    // let d=item.lineItems[0].discountInPercent
    // let amountReduced=(item.paymentAmount*d/100)
    // console.log(amountReduced);

    // Update the state
    setCartItems(updatedItems);

    // Save updated cart items to localStorage
    localStorage.setItem("cart", JSON.stringify(updatedItems));
  };

  const handleIncrement = (index: number) => {
    const currentQuantity = cartItems[index].lineItems[0].quantity;
    const newQuantity = currentQuantity + 100;

    if (newQuantity <= 1500) {
      updateCartItem(index, newQuantity);
    } else {
      // message.error("Maximum quantity limit reached");
      console.log("Maximum quantity limit reached");
    }
  };

  const handleDecrement = (index: number) => {
    const currentQuantity = cartItems[index].lineItems[0].quantity;
    if (currentQuantity > 250) {
      const newQuantity = currentQuantity - 100;
      updateCartItem(index, newQuantity);
    }
  };

  //Checkout
  const [checkoutData, setCheckoutData] = useState<CheckoutDataH>({
    lineItems: [
      {
        product: "",
        quantity: 2,
      },
    ],
    type: "",
    deliverySlot: "",
    address: "",
    paymentMethod: "",
    paymentStatus: "",
    status: "",
    deliveryInstructions: "",
    isCancelled: false,
    cancellationReason: null as string | null,
    refundStatus: "",
    paymentAmount: null,
    totalAmount: null,
    originalPrice: null,
    isMMpoints: false,
  });

  // const [slotset, setSlotset] = useState(false);
  // const handleSlot = (val: any) => {
  //   setCheckout({ ...checkout, deliverySlot: val });
  //   if (val === "07:00am-10:00am") {
  //     setSlotset(true);
  //   } else {
  //     setSlotset(false);
  //   }
  // };

  useEffect(() => {
    // Retrieve the cart data from localStorage
    const cartData: OrderData[] = JSON.parse(
      localStorage.getItem("cart") || "[]"
    );

    // Generate a unique order ID (you may want to use a more sophisticated method)
    const orderId = Date.now();

    // Prepare the checkout data
    setCheckoutData({
      ...checkoutData,
      lineItems: cartData.flatMap((item) =>
        item.lineItems.map((lineItem) => ({
          product: lineItem.product,
          quantity: lineItem.quantity,
          productPrice: lineItem.productPrice,
          discountInPercent: lineItem.discountInPercent,
          discountedPrice: lineItem.discountedPrice,
        }))
      ),
      type: cartData.length > 0 ? cartData[0].type : "normal",
      deliverySlot: slotset ? slotset : slotset,
      address: userData.address ? userData.address.street + ",+" : "",
      paymentMethod: checkout.paymentMethod ? checkout.paymentMethod : "",
      paymentStatus: checkout.paymentStatus ? checkout.paymentStatus : "",
      status: checkout.status ? checkout.status : "",
      deliveryInstructions:
        cartData.length > 0 ? cartData[0].deliveryInstructions : "",
      isCancelled: cartData.length > 0 ? cartData[0].isCancelled : false,
      cancellationReason:
        cartData.length > 0 ? cartData[0].cancellationReason : null,
      refundStatus: cartData.length > 0 ? cartData[0].refundStatus : "",
      paymentAmount: checkout.paymentAmount ? checkout.paymentAmount : 0,
      totalAmount: cartData.reduce((acc, item) => {
        const lineItemTotal = item.lineItems.reduce((lineAcc, lineItem) => {
          return lineAcc + lineItem.productPrice;
        }, 0);
        return acc + lineItemTotal;
      }, 0),
    });
  }, [cartItems, checkout, slotset, userData]);

  const [paymentMethod, setPaymentMethod] = useState("card"); // Default to card

  //Post Transition
  const handlepostTransition = async (id: any) => {
    try {
      let datauser = {
        order: id,
        user: userData._id,
        amount: checkout.isMMpoints ? total - userData.mmPoints : total,
        date: new Date(),
      };
      const result = await amountTransfer(ApiEndPoints("transfer"), datauser);
      setCheckout({ ...checkout, isMMpoints: false });
    } catch (error) {
      console.error("Error posting order:", error);
    }
  };

  const handlepostOrders = async (updatedCheckout: any) => {
    const updatedCheckoutData = {
      ...checkoutData,
      ...updatedCheckout,
      address:
        userData.address.street +
        "," +
        userData.address.city +
        "," +
        userData.address.state +
        "," +
        userData.address.pincode,
      deliverySlot: slotset && slotset,
      paymentAmount: checkout.isMMpoints ? total - userData.mmPoints : total,
      deliveryDate: selectedDate,
      finalAmount: checkout.isMMpoints ? total - userData.mmPoints : total,
      totalAmount: checkout.isMMpoints ? total - userData.mmPoints : total,
    };

    try {
      const result = await postOrder(
        ApiEndPoints("postOrder"),
        updatedCheckoutData
      );

      await handlepostTransition(result.order._id);

      successmessage("Order placed successfully");
      setconformation(false);
      setIsConform(true);
      // navigate("/my-order");
      localStorage.removeItem("cart");
      setIsCart({
        cart: false,
        summary: false,
        newAddress: false,
        payment: false,
      });
    } catch (error) {
      errormessage(
        "There was an error processing your order. Please try again."
      );
    }
  };

  const handlePayment = async (e: any) => {
    // Load Razorpay script
    const scriptLoaded = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!scriptLoaded) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // Define payment options
    const options = {
      key: "rzp_test_mNgkakUh48YruG", // Your Razorpay Key ID
      amount: checkoutData.paymentAmount
        ? checkoutData.paymentAmount * 100
        : total * 100, // Amount in paise
      currency: "INR",
      name: "Meet demo",
      description: "Test Transaction",
      order_id: "", // Ensure you pass a valid order ID
      handler: function (response: any) {
        console.log("Payment response", response.razorpay_payment_id);
        // Prepare the updated checkout object
        const updatedCheckout = {
          ...checkout, // assuming `checkout` is a state variable
          paymentStatus: "pending",
          status: "pending",
          paymentMethod: e,
          refundStatus: "pending",
          date: new Date(),
        };

        // Update the checkout state
        setCheckout(updatedCheckout);

        // Call the API with the updatedCheckout
        handlepostOrders(updatedCheckout);
      },
      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Soumya Dey Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    try {
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error initializing Razorpay", error);
    }
  };

  // const handleCardPaymentClick = (e: any) => {
  //   if (e === "upi") {
  //     setPaymentMethod("upi");
  //     handlePayment(e);
  //   } else if (e === "card") {
  //     setPaymentMethod("card");
  //     handlePayment(e);
  //   } else {
  //     setPaymentMethod("cash");

  //     // Update checkout state and then post the order
  //     // setCheckout((prevCheckout) => {
  //     //   const updatedCheckout = {
  //     //     ...prevCheckout,
  //     //     paymentStatus: "pending",
  //     //     status: "pending",
  //     //     paymentMethod: e,
  //     //     refundStatus: "pending",
  //     //     date: new Date(),
  //     //   };

  //     const updatedCheckout = {
  //       ...checkout, // assuming `checkout` is a state variable
  //       paymentStatus: "pending",
  //       status: "pending",
  //       paymentMethod: e,
  //       refundStatus: "pending",
  //       date: new Date(),
  //     };

  //       // Update the checkout state
  //   setCheckout(updatedCheckout);

  //   // Call the API with the updatedCheckout
  //   handlepostOrders(updatedCheckout);

  //       // Call the API with the updatedCheckout
  //       handlepostOrders(updatedCheckout);
  //       return updatedCheckout;
  //     });
  //   }
  // };

  // Load script function

  const [conformation, setconformation] = useState(false);
  const [isCosnform, setIsdconform] = useState(false);

  const handleConfromClose = () => {
    setconformation(false);
  };

  const handlecancelconform = () => {
    setIsdconform(false);
    setconformation(false);
  };

  const handleConformoo = async () => {
    // setconformation(false)
    setIsdconform(true);
  };

  useEffect(() => {
    if (isCosnform) {
      handleCardPaymentClick("upi");
    }
  }, [isCosnform]);

  const handleCardPaymentClick = (e: any) => {
    if (e === "upi") {
      // setconformation(true)
      // if (isCosnform) {
      setPaymentMethod("upi");
      // Prepare the updated checkout object
      const updatedCheckout = {
        ...checkout, // assuming `checkout` is a state variable
        paymentStatus: "pending",
        status: "pending",
        paymentMethod: e,
        refundStatus: "pending",
        createdAt: new Date(),
      };

      // Update the checkout state
      setCheckout(updatedCheckout);

      // Call the API with the updatedCheckout
      handlepostOrders(updatedCheckout);
      // }
      // handlePayment(e);
    } else if (e === "card") {
      // setconformation(true)

      setPaymentMethod("card");
      // Prepare the updated checkout object
      const updatedCheckout = {
        ...checkout, // assuming `checkout` is a state variable
        paymentStatus: "pending",
        status: "pending",
        paymentMethod: e,
        refundStatus: "pending",
        createdAt: new Date(),
      };

      // Update the checkout state
      setCheckout(updatedCheckout);

      // Call the API with the updatedCheckout
      handlepostOrders(updatedCheckout);

      // handlePayment(e);
    } else {
      setPaymentMethod("cash");

      // Prepare the updated checkout object
      const updatedCheckout = {
        ...checkout, // assuming `checkout` is a state variable
        paymentStatus: "pending",
        status: "pending",
        paymentMethod: e,
        refundStatus: "pending",
        createdAt: new Date(),
      };

      // Update the checkout state
      setCheckout(updatedCheckout);

      // Call the API with the updatedCheckout
      handlepostOrders(updatedCheckout);
    }
    setconformation(false);
  };

  function loadScript(src: any) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  }

  const [isSnackbar, setisSnackbar] = React.useState(false);

  const handleClicksnack = () => {
    setisSnackbar(true);
  };

  const handleClosesnack = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setisSnackbar(false);
  };

  const [isDropdown, setisdropdown] = useState(false);
  const [isFranciesOpen, setIsFranciesOpen] = useState(false);
  const [isBulkOpen, setIsBulkOpen] = useState(false);
  const openModal = () => {
    setIsFranciesOpen(true);
  };

  const openulkModal = () => {
    setIsBulkOpen(true);
  };

  const closeModal = () => {
    setIsFranciesOpen(false);
  };
  const closeBulkModal = () => {
    setIsBulkOpen(false);
  };

  // const handlecancelCart = (index: number) => {
  //   console.log(index, "index");

  //   // Retrieve the cart items from localStorage
  //   const cartcancel = JSON.parse(localStorage.getItem('cartItems') || '[]');

  //   // Check if the index is within the bounds of the array
  //   if (index >= 0 && index < cartcancel.length) {
  //     // Remove the item at the specified index
  //     cartcancel.splice(index, 1);

  //     // Update the cart items in localStorage
  //     localStorage.setItem('cartItems', JSON.stringify(cartcancel));

  //     // Update the state
  //     setCartItems(cartcancel);

  //     console.log(cartcancel, "updated cartItems");
  //   } else {
  //     console.log("Invalid index");
  //   }
  // };

  const handlecancelCart = (index: number) => {
    // Retrieve the cart items from localStorage
    const cartcancel = JSON.parse(localStorage.getItem("cart") || "[]");

    // Check if the index is within the bounds of the array
    if (index >= 0 && index < cartcancel.length) {
      // Remove the item at the specified index
      cartcancel.splice(index, 1);
      // Update the cart items in localStorage
      localStorage.setItem("cart", JSON.stringify(cartcancel));

      // Update the state
      setCartItems(cartcancel);
    } else {
      console.log("Invalid index");
    }
  };

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  const handleFocus = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker(); // This will trigger the date picker to open
    }
  };

  useEffect(() => {
    // Function to check sessionStorage and update state
    const checkSessionStorage = () => {
      const sessionValue = sessionStorage.getItem("openCart");

      if (sessionValue === "true") {
        setIsCart({
          cart: true,
          summary: false,
          newAddress: false,
          payment: false,
        });
        return;
      }
    };

    // Initial check
    checkSessionStorage();

    // Set up an interval to check sessionStorage every 5000 milliseconds (5 seconds)
    const intervalId = setInterval(checkSessionStorage, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleLoginSuccess = (response: any) => {
    console.log("Login Success:", response);
    // You can access user info here
    const { profileObj, tokenId } = response;
    console.log("User Profile:", profileObj);
    console.log("Token ID:", tokenId);

    // Save the token or user info as needed
    // localStorage.setItem('token', tokenId);
  };

  const handleLoginFailure = (response: any) => {
    console.error("Login Failed:", response);
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };




  return (
    <>
      {contextHolder}
      <Francies open={isFranciesOpen} closeModal={closeModal} />
      <BulkOrders open={isBulkOpen} closeModal={closeBulkModal} />
      <div style={{ marginBottom: "5rem" }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed">
            {/* <Container> */}
            <div className="mx-3">
              <Toolbar className="position-relative">
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleShow}
                    color="inherit"
                  >
                    <RxHamburgerMenu style={{ color: "#fff" }} />
                  </IconButton>
                </Box>

                <Typo
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ display: { xs: "block", sm: "block" } }}
                >
                  <Link to="/">
                    <img
                      className="img-fluid py-2"
                      src="/assets/halalhead.png"
                      style={{
                        width: "100px",
                        height: "80px",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Typo>
                <div className="lg-Search d-none d-sm-block">
                  <Search>
                    <SearchIconWrapper>
                      <IoIosSearch style={{ color: "#525252" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search...."
                      inputProps={{ "aria-label": "search" }}
                      onChange={handleSearch}
                      value={isSearch}
                    />
                  </Search>
                </div>
                <div className="list-items">
                  <ul>
                    <li onMouseEnter={() => setisdropdown(false)}>
                      <Link to="/chicken">Chicken</Link>
                    </li>
                    <li
                      className="mx-4"
                      onMouseEnter={() => setisdropdown(false)}
                    >
                      <Link to="/mutton">Mutton</Link>
                    </li>
                    <li
                      className="p-0 position-relative"
                      onClick={() => setisdropdown(true)}
                      onMouseEnter={() => setisdropdown(true)}
                    >
                      <a style={{ cursor: "pointer" }}>
                        Business With Us{" "}
                        <RiArrowDropDownLine style={{ fontSize: "2rem" }} />
                      </a>
                    </li>
                    {isDropdown && (
                      <div
                        className="businessus"
                        onMouseEnter={() => setisdropdown(true)}
                        onMouseLeave={() => setisdropdown(false)}
                      >
                        <ul>
                          <li>
                            <a onClick={openulkModal}>For Bulk Orders</a>
                          </li>
                          {/* <li>
                            <a onClick={openModal}>Become A Franchise</a>
                          </li> */}
                        </ul>
                      </div>
                    )}
                  </ul>
                </div>

                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                    <Badge>
                      <TbTruckDelivery
                        className="head-Icon"
                        onClick={handleOrders}
                        onMouseEnter={() => setisdropdown(false)}
                      />
                    </Badge>
                  </IconButton>
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={handleNavCart}
                    onMouseEnter={() => setisdropdown(false)}
                  >
                    <Badge
                      badgeContent={cartItems.length ? cartItems.length : "0"}
                      color="primary"
                    >
                      <IoBagOutline className="head-Icon" />
                    </Badge>
                  </IconButton>
                  {isUser ? (
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      className="position-relative"
                      color="inherit"
                      onClick={handleNaUserdetail}
                      onMouseEnter={() => setisdropdown(false)}
                    >
                      <CgProfile className="head-Icon" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      className="position-relative"
                      color="inherit"
                      onClick={handleNavProfile}
                      onMouseEnter={() => setisdropdown(false)}
                    >
                      <CgProfile className="head-Icon" />
                    </IconButton>
                  )}
                  {/* <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    className="position-relative"
                    color="inherit"
                    onClick={() => setIsLocation(true)}
                  >
                    <MdMyLocation className="head-Icon" />
                  </IconButton> */}
                </Box>

                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={handleNavCart}
                  >
                    <Badge
                      badgeContent={cartItems.length ? cartItems.length : "0"}
                      color="primary"
                    >
                      <IoCartSharp className="head-Icon" />
                    </Badge>
                  </IconButton>
                  {isUser ? (
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      className="position-relative"
                      color="inherit"
                      onClick={handleNaUserdetail}
                      onMouseEnter={() => setisdropdown(false)}
                    >
                      <CgProfile className="head-Icon" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      className="position-relative"
                      color="inherit"
                      onClick={handleNavProfile}
                      onMouseEnter={() => setisdropdown(false)}
                    >
                      <CgProfile className="head-Icon" />
                    </IconButton>
                  )}
                </Box>
                {/* Address */}
                {isSign.address && (
                  <div className="log-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Account Details</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handleCloseIcon}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="table">
                        <table>
                          <tr>
                            <td>
                              <strong>Name</strong>
                            </td>
                            <td colSpan={2}>{userData.name || ""}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email</strong>
                            </td>
                            <td colSpan={2}>{userData.email || ""}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Contact</strong>
                            </td>
                            <td colSpan={2}>{userData.phone || null}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Address</strong>
                            </td>
                            {userData.address.city ? (
                              <td colSpan={2}>
                                {userData.address.street +
                                  ", " +
                                  userData.address.city +
                                  "," +
                                  userData.address.state +
                                  "," +
                                  userData.address.pincode || "N/A"}
                              </td>
                            ) : (
                              <td colSpan={2}>No address found</td>
                            )}
                          </tr>
                          <tr>
                            <td>
                              <strong>Reward Coins</strong>
                            </td>
                            <td className="d-flex judtify-content-start align-items-center">
                              {userData.mmPoints}
                              <img
                                src="/assets/m-coin.gif"
                                style={{ width: "25px" }}
                                alt="coins"
                                className="ig-fluid"
                                loading="lazy"
                              />
                            </td>
                          </tr>
                        </table>
                        <div className="text-end logout py-3">
                          <a
                            onClick={handlelogOut}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            LogOut
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Signin */}
                {isSign.login && (
                  <div className="log-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Login</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handleCloseIcon}
                        />
                      </div>
                    </div>
                    <div className="login">
                      <label>
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        value={isloginData.email}
                        onChange={(e) =>
                          setIsloginData({
                            ...isloginData,
                            email: isLogmail ? isLogmail : e.target.value,
                          })
                        }
                      />
                      <br />

                      <label>
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />

                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        <input
                          type={isPasswordVisible ? "text" : "password"}
                          onChange={(e) =>
                            setIsloginData({
                              ...isloginData,
                              password: e.target.value,
                            })
                          }
                          value={isloginData.password}
                          style={{ paddingRight: "2rem" }}
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "0.5rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {isPasswordVisible ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}{" "}
                          {/* Open eye for view, closed eye for hide */}
                        </span>
                      </div>
                      {isloginError !== "" && (
                        <p
                          className="mb-0 pt-2 text-center"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {isloginError}
                        </p>
                      )}
                      <div className="text-center py-4">
                        <Button variant="contained" onClick={handleLoginuser}>
                          Sign In
                        </Button>
                      </div>
                      <p>
                        New Customer?{" "}
                        <a onClick={() => handleLogin("signUp")}>
                          Create Your account
                        </a>
                      </p>
                      <p>
                        Forget password?{" "}
                        <a onClick={() => handleLogin("forgetPass")}>
                          Recovery password
                        </a>
                      </p>
                    </div>
                  </div>
                )}

                {/* Signup */}
                {isSign.signup && (
                  <div className="log-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Register</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handleCloseIcon}
                        />
                      </div>
                    </div>
                    <div className="login">
                      <label>
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        onChange={(e) =>
                          setisSignupData({
                            ...isSignupData,
                            name: e.target.value,
                          })
                        }
                      />
                      <br />
                      <label>
                        Phone number <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        onChange={(e) =>
                          setisSignupData({
                            ...isSignupData,
                            phone: Number(e.target.value),
                          })
                        }
                      />
                      <br />
                      {errorAlert.toLocaleLowerCase().includes("phone") && (
                        <>
                          <span style={{ color: "red" }}>
                            Phone number already existed
                          </span>
                          <br />
                        </>
                      )}

                      <label>
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        onChange={(e) =>
                          setisSignupData({
                            ...isSignupData,
                            email: e.target.value,
                          })
                        }
                      />
                      <br />
                      {errorAlert.toLocaleLowerCase().includes("email") && (
                        <>
                          <span style={{ color: "red" }}>
                            Email already existed
                          </span>
                          <br />
                        </>
                      )}
                      <label>
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        onChange={(e) =>
                          setisSignupData({
                            ...isSignupData,
                            password: e.target.value,
                          })
                        }
                      />
                      {issignupError !== "" && (
                        <p
                          className="mb-0 pt-2 text-center"
                          style={{ color: "red", fontSize: "12px" }}
                        >
                          {issignupError}
                        </p>
                      )}
                      {/* <GoogleLogin
                        clientId="667762070555-3ahgsbafc9mnufo43c1g50ttnlrkedis.apps.googleusercontent.com" // Replace with your Client ID
                        buttonText="Login with Google"
                        onSuccess={handleLoginSuccess}
                        onFailure={handleLoginFailure}
                        cookiePolicy={"single_host_origin"} // For local development
                      /> */}
                      <div className="text-center py-4">
                        <Button variant="contained" onClick={handleSignUP}>
                          Create Account
                        </Button>
                      </div>
                      <p>
                        Already Have an Account?{" "}
                        <a onClick={() => handleLogin("login")}>Login here</a>
                      </p>
                    </div>
                  </div>
                )}

                {/* RecoveryPassword */}
                {isSign.forgetpass && (
                  <div className="log-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Recovery Password</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handleCloseIcon}
                        />
                      </div>
                    </div>
                    <div className="login">
                      <label>
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <br />
                      <input
                        onChange={(e) => setIsForgetpass(e.target.value)}
                      />
                      <br />
                      <p
                        className="text-center pt-3 mb-0"
                        style={{ color: "red" }}
                      >
                        {resetMessage}
                      </p>

                      <div
                        className="text-center"
                        style={{ padding: "2rem 0" }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleForgetPassword}
                        >
                          Reset Password
                        </Button>
                      </div>
                      <p>
                        Remember password?{" "}
                        <a onClick={() => handleLogin("login")}>Login here</a>
                      </p>
                    </div>
                  </div>
                )}

                {/* OTP */}
                {isSign.otp && (
                  <div className="log-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Enter OTP</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handleCloseIcon}
                        />
                      </div>
                    </div>
                    <div className="login">
                      <label>Enter OTP</label>
                      <br />
                      <Input.OTP length={4} {...sharedProps} />

                      <div
                        className="text-center"
                        style={{ padding: "3rem 0" }}
                      >
                        <Button variant="contained">Submit</Button>
                      </div>
                      <p>
                        Remember password?{" "}
                        <a onClick={() => handleLogin("login")}>Login here</a>
                      </p>
                    </div>
                  </div>
                )}

                {/* Reset password */}
                {isSign.resetpassword && (
                  <div className="log-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Reset Password</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handleCloseIcon}
                        />
                      </div>
                    </div>
                    <div className="login">
                      <label>New Password</label>
                      <br />
                      <input />
                      <br />
                      <label>Confirm Password</label>
                      <br />
                      <input />
                      <div className="text-center py-4">
                        <Button variant="contained">Update</Button>
                      </div>
                      <p>
                        New Customer?{" "}
                        <a onClick={() => handleLogin("signUp")}>
                          Create Your account
                        </a>
                      </p>
                    </div>
                  </div>
                )}

                {/* Location */}
                {isLocation && (
                  <div className="log-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Location</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() => setIsLocation(false)}
                        />
                      </div>
                    </div>
                    <div className="login">
                      <label>Enter Your Delivery Location </label>
                      <br />
                      <input defaultValue={address ? address : ""} />
                      <br />

                      <div
                        className="text-center"
                        style={{ padding: "3rem 0 1rem 0" }}
                      >
                        <Button variant="contained">Proceed</Button>
                      </div>
                      <h4 className="text-center">OR</h4>
                      <div
                        className="text-center"
                        style={{ padding: "1rem 0 3rem 0" }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleLocatiocDetect}
                        >
                          Detect My Location
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                {/* cart */}

                {cartItems.length > 0 && isCart.cart ? (
                  <div className="log-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Cart ({cartItems.length})</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handlecartClose}
                        />
                      </div>
                    </div>
                    <div className="login tablecart">
                      <table>
                        <tbody>
                          {cartItems.map((item, index) => {
                            // Calculate the price per 100g
                            const pricePer100g =
                              (item.originalPrice / item.originalQuantity) *
                              100;

                            // Calculate the total price based on the selected weight
                            const totalPrice =
                              (item.lineItems[0].quantity / 100) * pricePer100g;

                            const discountedPrice = item.paymentAmount;

                            return (
                              <tr key={index}>
                                <td className="pe-1">{item.productName}</td>
                                <td className="px-1">
                                  <div className="increDec justify-content-between">
                                    <span
                                      className="minus"
                                      onClick={() => handleDecrement(index)}
                                    >
                                      -
                                    </span>
                                    <span
                                      className="inputQuantity"
                                      style={{ width: "40px" }}
                                    >
                                      {item.lineItems[0].quantity}g
                                    </span>
                                    <span
                                      className="plus"
                                      onClick={() => handleIncrement(index)}
                                    >
                                      +
                                    </span>
                                  </div>
                                </td>
                                <td className="ps-2">
                                  <p className="mb-0 text-center">
                                    {`₹` + discountedPrice.toFixed(2)}
                                  </p>
                                  <p className="mb-0 strike text-center">
                                    {`₹` + totalPrice.toFixed(2)}
                                  </p>
                                </td>
                                <td>
                                  <MdCancel
                                    onClick={() => handlecancelCart(index)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div
                        className="text-center"
                        style={{ padding: "3rem 0 1rem 0" }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleCartNav("summary")}
                        >
                          Proceed To Check Out
                        </Button>
                      </div>

                      <div>
                        <Snackbar
                          open={isSnackbar}
                          autoHideDuration={5000}
                          onClose={handleClosesnack}
                          message="Please Login !"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* Summary */}
                {isCart.summary && (
                  <div className="log-box summary-box">
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Summary</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handlecartClose}
                        />
                      </div>
                    </div>
                    <div className="login tablecart table-sum">
                      <h4 className="summary-head">Product Summary :</h4>
                      <table>
                        <tbody>
                          <tr>
                            <td>Items</td>
                            <td className="text-center">Price</td>
                          </tr>
                          {cartItems.map((item, index) => {
                            // Calculate the price per 100g
                            const pricePer100g =
                              (item.originalPrice / item.originalQuantity) *
                              100;

                            // Calculate the total price based on the selected weight
                            const totalPrice =
                              (item.lineItems[0].quantity / 100) * pricePer100g;

                            const discountedPrice = item.paymentAmount;
                            const showamount = checkout.isMMpoints
                              ? discountedPrice - userData.mmPoints
                              : discountedPrice;
                            return (
                              <>
                                <tr key={index}>
                                  <td
                                    className="pe-1 sum-td"
                                    style={{ borderBottom: "0px" }}
                                  >
                                    {item.productName}
                                  </td>
                                  <td
                                    className="ps-2"
                                    style={{ borderBottom: "0px" }}
                                  >
                                    <p className="mb-0 text-center">
                                      {`₹` + discountedPrice.toFixed(2)}
                                    </p>
                                    <p className="mb-0 strike text-center">
                                      {`₹` + totalPrice.toFixed(2)}
                                    </p>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                      <hr className="my-1" />

                      <h4 className="summary-head mt-1">Price Summary :</h4>

                      <table className="">
                        <tr>
                          <td>Price Details:</td>
                          <td className="text-end">₹(Rs)</td>
                        </tr>
                        <tr>
                          <td>Subtotal</td>
                          <td className="text-end">
                            {checkout.paymentAmount?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Delivery Charges{" "}
                            {checkout.paymentAmount &&
                            checkout.paymentAmount > 100
                              ? "(Free)"
                              : "(Above 100 free)"}
                          </td>
                          <td className="text-end">
                            {checkout.paymentAmount &&
                            checkout.paymentAmount > 100 ? (
                              <>
                                <p className="mb-0">Free</p>
                                <span className="strike">45</span>
                              </>
                            ) : (
                              "25"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Order Total</td>
                          <td className="text-end" style={{ color: "#000" }}>
                            {(() => {
                              // Calculate the intermediate value based on conditions
                              const dd = checkout.isMMpoints
                                ? Number(checkout.paymentAmount) -
                                  userData.mmPoints
                                : checkout.paymentAmount;

                              // Calculate the final payment amount and format it to two decimal places
                              const paymentAmount =
                                dd !== null && dd > 100
                                  ? dd
                                  : (Number(dd) ?? 0) + 0; //25

                              return paymentAmount.toFixed(2); // Ensure two decimal points
                            })()}
                          </td>
                        </tr>

                        {/* <tr>
                          <td>Apply Referral Point</td>
                          <td className="text-end">Apply</td>
                        </tr> */}
                      </table>
                      <p
                        className="mb-0 text-end"
                        style={{ color: "green", fontWeight: 500 }}
                      >
                        {checkout.isMMpoints ? "Coins applied!" : ""}
                      </p>

                      <div>
                        <h5 className="h5-sum">Select Delivery Slot</h5>
                        {/* <Radio.Group
                          onChange={onRadioChange}
                          value={radiovalue}
                        >
                          <Radio value={"today"} disabled={isSlotDisabled("05:00pm")}>Today</Radio>
                          <Radio value={"tomorrow"}>Tomorrow</Radio>
                        </Radio.Group>
                        <div className="pt-3">
                          <Radio.Group
                            onChange={(e) => handleSlot(e.target.value)}
                            value={slotset}
                          >
                            <Radio
                              value={"07:00am-10:00am"}
                              disabled={isSlotDisabled("07:00am")}
                            >
                              07:00am-10:00am
                            </Radio>
                            <Radio
                              value={"05:00pm-07:30pm"}
                              disabled={isSlotDisabled("05:00pm")}
                            >
                              05:00pm-07:30pm
                            </Radio>
                          </Radio.Group>
                        </div> */}
                        <Radio.Group
                          onChange={onRadioChange}
                          value={radiovalue}
                        >
                          {renderDayOptions()}
                        </Radio.Group>
                        <div className="pt-3">
                          <Radio.Group
                            onChange={(e) => handleSlot(e.target.value)}
                            value={slotset}
                          >
                            <Radio
                              value={"07:00am-11:00am"}
                              disabled={isSlotDisabled("07:00am")}
                            >
                              07:00am-11:00am
                            </Radio>
                            <Radio
                              value={"05:00pm-09:00pm"}
                              disabled={isSlotDisabled("05:00pm")}
                            >
                              05:00pm-09:00pm
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>

                      {/* <h5 className="h5-sum">Apply Discount Code</h5>
            <div>
              <Space.Compact style={{ width: "100%" }}>
                <Input />
                <ButtonAnt type="primary" className="ant-btnsubmit">
                  Apply
                </ButtonAnt>
              </Space.Compact>
            </div> */}
                      {userData.mmPoints > 0 && (
                        <div>
                          <label>Points</label>
                          <br />
                          <Checkbox
                            checked={checkout.isMMpoints}
                            onChange={(e) =>
                              setCheckout((prev) => ({
                                ...prev,
                                isMMpoints: e.target.checked,
                              }))
                            }
                          >
                            {" "}
                            Use MM Coins ({userData.mmPoints || 0} available)
                          </Checkbox>
                        </div>
                      )}
                      <div className="py-3 text-center">
                        <Button
                          variant="contained"
                          onClick={() => handleCartNav("newaddress")}
                        >
                          Checkout
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                {/* AddNewAddress */}
                {isCart.newAddress && (
                  <div
                    className="log-box"
                    style={{ overflowY: "scroll", height: "80vh" }}
                  >
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Add New Address</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handlecartClose}
                        />
                      </div>
                    </div>
                    <div className="address">
                      <h3>Personal Details</h3>
                      <label>
                        Full Name <span>*</span>
                      </label>
                      <br />
                      <input
                        value={userData.name ? userData.name : ""}
                        onChange={(e) =>
                          setUserData({ ...userData, name: e.target.value })
                        }
                        disabled
                      />
                      <label>
                        Phone Number <span>*</span>
                      </label>
                      <br />
                      <input
                        defaultValue={userData.phone ? userData.phone : ""}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            phone: Number(e.target.value),
                          })
                        }
                      />
                      <br />
                      <label>
                        Email <span>*</span>
                      </label>
                      <br />
                      <input
                        defaultValue={userData.email ? userData.email : ""}
                        onChange={(e) =>
                          setUserData({ ...userData, email: e.target.value })
                        }
                        disabled
                      />
                      <br />
                      <h3 className="pt-3">Address</h3>
                      <label>
                        House no, Building, Road, Area <span>*</span>
                      </label>
                      <br />
                      <input
                        defaultValue={userData.address.street}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            address: {
                              ...userData.address,
                              street: e.target.value,
                            },
                          })
                        }
                      />
                      <br />
                      <label>
                        Pincode <span>*</span>
                      </label>
                      <br />
                      <input
                        defaultValue={
                          userData.address.pincode
                            ? userData.address.pincode
                            : 0
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                        
                          setUserData((prevUserData) => ({
                            ...prevUserData,
                            address: {
                              ...prevUserData.address,
                              pincode: value ? Number(value) : null, // Use null instead of an empty string
                            },
                          }));
                        
                          setpincodeerror(""); // Clear the pincode error
                        }}
                        style={{
                          borderColor: pincodeerror ? "red" : "initial", // Highlight the input box in red if there's an pincodeerror
                        }}
                      />
                      {pincodeerror && <p style={{ color: "red", fontSize: "12px" }}>{pincodeerror}</p>} {/* pincodeerror message */}
                      <br />
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="pe-1">
                          <label>
                            City <span>*</span>
                          </label>
                          <br />
                          <input
                            defaultValue={userData.address.city}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                address: {
                                  ...userData.address,
                                  city: e.target.value,
                                },
                              })
                            }
                          />
                          <br />
                        </div>
                        <div className="ps-1">
                          <label>
                            State <span>*</span>
                          </label>
                          <br />
                          <input
                            defaultValue={userData.address.state}
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                address: {
                                  ...userData.address,
                                  state: e.target.value,
                                },
                              })
                            }
                          />
                          <br />
                        </div>
                      </div>
                      <label>Save Address As</label>
                      <br />
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <Button
                            variant="contained"
                            className={`${
                              isTypebtn.home ? "loc-btn" : "loc-btn2"
                            }`}
                            onClick={() => handleAddressTypeChange("Home")}
                          >
                            Home
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            className={`${
                              isTypebtn.work ? "loc-btn" : "loc-btn2"
                            }`}
                            onClick={() => handleAddressTypeChange("Work")}
                          >
                            Work
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            className={`${
                              isTypebtn.other ? "loc-btn" : "loc-btn2"
                            }`}
                            onClick={() => handleAddressTypeChange("Other")}
                          >
                            Other
                          </Button>
                        </div>
                      </div>
                      <div className="text-center py-3">
                        <Button
                          variant="contained"
                          className="savebtn"
                          onClick={() => handleCartNav("payment")}
                        >
                          Save and Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                {/* PayMent */}
                {isCart.payment && (
                  <div
                    className="log-box"
                    style={{ overflowY: "scroll", height: "80vh" }}
                  >
                    <div className="d-flex justify-content-between align-items-center box-title">
                      <div>
                        <h3>Payment</h3>
                      </div>
                      <div>
                        <IoMdCloseCircleOutline
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={handlecartClose}
                        />
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="d-flex justify-content-between align-items-center order-box">
                        <div>
                          <p>
                            <FaShoppingCart /> Ordered Items
                          </p>
                        </div>
                        <div>
                          <p>{checkoutData.lineItems.length} Items</p>
                        </div>
                      </div>
                      <div className=" order-box mt-2">
                        <div className="d-flex justify-content-between align-items-center pb-2">
                          <div>
                            <p>
                              <TbTruckDelivery /> Delivery
                            </p>
                          </div>
                          {/* <div>
              <p>
                <MdEdit />
              </p>
            </div> */}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#E7E7E7",
                            padding: "12px",
                          }}
                        >
                          <p>
                            {userData.address.street +
                              "," +
                              userData.address.city +
                              "," +
                              userData.address.state +
                              "," +
                              userData.address.pincode}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div
                          className="d-flex  align-items-center mt-3 pay"
                          style={{
                            height: "70px",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => handleCardPaymentClick("upi")}
                          // onClick={() => setconformation(true)}
                        >
                          <div className="razor-div">
                            <img
                              className="img-fluid"
                              width={"30px"}
                              src="/assets/mobilepayment.png"
                            />
                          </div>
                          <div style={{ width: "60%", paddingLeft: "10px" }}>
                            <h4>Pay Via UPI</h4>
                            <p>Use any registered UPI Id</p>
                          </div>
                          <div style={{ width: "20%" }}>
                            <h4>
                              {checkout.isMMpoints
                                ? (
                                    Number(checkout.paymentAmount) -
                                    userData.mmPoints
                                  ).toFixed(2)
                                : total.toFixed(2)}
                              <IoIosArrowForward />
                              {/* {total.toFixed()} */}
                            </h4>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center mt-3 pay"
                          style={{
                            height: "70px",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => handleCardPaymentClick("card")}
                          // onClick={() => setconformation(true)}
                        >
                          <div className="razor-div">
                            <img
                              className="img-fluid"
                              width={"30px"}
                              src="/assets/card (2).png"
                            />
                          </div>
                          <div style={{ width: "60%", paddingLeft: "10px" }}>
                            <h4>Debit/Credit Cards</h4>
                            <p>Visa, Mastercard, RuPay & more</p>
                          </div>
                          <div style={{ width: "20%" }}>
                            <h4>
                              {checkout.isMMpoints
                                ? (
                                    Number(checkout.paymentAmount) -
                                    userData.mmPoints
                                  ).toFixed(2)
                                : total.toFixed(2)}

                              <IoIosArrowForward />
                            </h4>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center mt-3 pay"
                          style={{
                            height: "70px",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => handleCardPaymentClick("cash")}
                        >
                          <div className="razor-div">
                            <img
                              className="img-fluid"
                              width={"30px"}
                              src="/assets/cash.png"
                            />
                          </div>
                          <div style={{ width: "60%", paddingLeft: "10px" }}>
                            <h4>Cash On Delivery</h4>
                            <p>Pay With Cash</p>
                          </div>
                          <div style={{ width: "20%" }}>
                            <h4>
                              {checkout.isMMpoints
                                ? (
                                    Number(checkout.paymentAmount) -
                                    userData.mmPoints
                                  ).toFixed(2)
                                : total.toFixed(2)}

                              <IoIosArrowForward />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Toolbar>
            </div>
            {/* </Container> */}
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
        </Box>

        {/* Order conform pop-up */}
        <div>
          <Modal
            open={isConform}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="text-center">
                <img
                  className="img-fluid verify-tick"
                  src="/assets/verified.gif"
                />
                <h2>Order Placed Successfully</h2>
                <p>
                  Thank you for your order! You can pay upon receiving our fresh
                  meat.
                </p>
                <a
                  className="conform-okay"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsConform(false)}
                >
                  OK
                </a>
              </div>
            </Box>
          </Modal>
        </div>

        <div>
          <Offcanvas show={show}>
            <Offcanvas.Header
              closeButton
              onClick={handleClose}
              style={{ marginTop: "90px" }}
            >
              <Offcanvas.Title style={{ fontWeight: 700 }}>
                {" "}
                {/* <div className="lg-Search md-search">
      <Search>
        <SearchIconWrapper>
          <IoIosSearch style={{ color: "#D357A7" }} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search What u like...."
          inputProps={{ "aria-label": "search" }}
        />
      </Search>
    </div> */}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                className="px-3 pb-3 sm-sidebar"
                style={{ backgroundColor: "#fff", borderRadius: "20px" }}
              >
                <ul className="px-0 mobile-ul">
                  <li>
                    {" "}
                    <Link to="/chicken" onClick={handleClose}>
                      Chicken
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/mutton" onClick={handleClose}>
                      Mutton
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <a className="mobile-menu" onClick={handleOrders}>
                      My Orders
                    </a>
                  </li>
                  {/* <li>
                    {" "}
                    <a className="mobile-menu" onClick={handleMobileCart}>
                      Cart
                    </a>
                  </li> */}
                  <li>
                    {" "}
                    <a className="mobile-menu" onClick={handlemobileProfile}>
                      Profile
                    </a>
                  </li>
                </ul>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
      <Modal
        open={conformation}
        // onClose={handleConfromClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h2 className="text-center">
              You will be receiving a payment link to the given mobile number or
              email
            </h2>
            <img className="img-fluid verify-tick" src="/assets/verified.gif" />
            <h2>Order Placed Successfully</h2>
            <p>Thanks for purchasing with us</p>
            <div className="pt-3 d-flex justify-content-center align-items-center">
              <Button
                className="mx-1 conf-btn"
                variant="contained"
                onClick={handleConformoo}
              >
                Proceed
              </Button>
              <Button
                className="mx-1 conf-btn"
                variant="contained"
                onClick={handlecancelconform}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}